import React, { useState, useMemo } from "react";
import { graphql, navigate, Link } from "gatsby";
import styled from 'styled-components';
import { device } from "../utils";
import PageWrapper from "../components/PageWrapper";
import Sidebar from "../components/Sidebar";
import { Button, Container, Row, Form } from "react-bootstrap";
import { Select } from '../components/Core'
import VacancyListItem from "../components/VacancyListItem";
import SearchBar from "../components/SearchBar";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import SEO from "../components/seo";
import { useVacancySearch } from "../hooks/useVacancySearch";
import { getValuesFromQuery } from "../utils/search"
import Filters from "../components/Filters/Filters"
import { useFilters } from "../hooks/useFilters";
import { makeAllFiltersByQueryLabel } from "../utils/filters";
import Content from "../components/Sections/homepage/Content1";
import Slider from "../components/Sections/homepage/Slider";
import NoAuthRedirect from "../components/Core/NoAuthRedirect";

const sortByDefaultOptions = [
  {value: "", label: "date posted"},
  //{value: "VacancyClosingDate", label: "apply before date"},
  {value: "Office", label: "location"},
  {value: "Company", label: "company"}
]

const SearchBarWrapper = styled.div`
	z-index: 930;
	position: relative;
  transform: translateY(-30%);
  

	@media ${device.md} {
    transform: translateY(-50%);
	}
`

const SearchGrid = ( { data, location } ) => {

  // vacancies per page
  const DEFAULT_VACANCIES_PER_PAGE = 10;
  
  const allFilters = makeAllFiltersByQueryLabel(useFilters());
  
  // These default values come from the page query string
  const queryParams = getValuesFromQuery(location.search, allFilters)

  const [filters, setFilters] = useState(queryParams)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialFilters = useMemo(() => queryParams, [])

  const [sortKey, setSortKey] = useState(queryParams.sortKey || "")

  let sortByDefaultValue = sortByDefaultOptions.filter(o => o.value === sortKey)

  const handleSortFieldChange = (val) => {
    setSortKey(val)
    // setFilters((filters) => { 
    //   return { ...filters, sortKey: val }
    // })
  }

  const {
    vacancies,
    isFetching,
    filterCount,
    hasMore,
    setLoadMore,
    totalCount
  } = useVacancySearch(
    filters,
    allFilters,
    sortKey,
    false,
    DEFAULT_VACANCIES_PER_PAGE,
    data.allSharePointVacanciesList.nodes,
    initialFilters
  )

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }
  
  const headerConfig = {
    paddingBottom: 100,
  };

  return (
    <>
      <UnauthenticatedTemplate>
        <NoAuthRedirect location={location} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <PageWrapper
          headerConfig={headerConfig}
        >
          <SEO title="Search" />
          <SearchBarWrapper id="search-bar">
            <Container className="col-lg-10 col-xl-8 justify-content-center">
              <SearchBar 
                defaultTerm={filters.term}
                defaultLocation={filters.location}
                setFilters={setFilters} />
            </Container>
          </SearchBarWrapper>
          
          <div className="pb-13 pb-lg-25 position-relative">
            <Container>
              <Row>
                <div className="col-12 col-lg-4 col-xl-3">
                  <Sidebar>
                    <div className="mt-lg-12 mb-14">
                      <Button variant="primary" class="">
                        <Link 
                          to="/what-you-need-to-know" 
                          className="text-uppercase text-white text-left d-flex align-items-center"
                          style={{lineHeight: '1.5'}}
                        >
                          <i className="fa fa-clipboard-list mr-7 font-size-10"></i>
                          <span>Are you aware of the application criteria? Check them out.</span>
                        </Link>
                      </Button>
                    </div>
                    <Filters 
                        filters={filters}     
                        setFilters={setFilters} />
                  </Sidebar>
                </div>
                {/* <!-- Search Results Heading --> */}
                <div className="col-12 col-lg-8 col-xl-9 mt-8 mt-lg-0">
                  <Row className="main-body-header d-flex">
                    <div className="col-sm">
                      <span className="font-size-7 font-weight-bold text-gray m-0">
                        <span className="heading-default-color">{totalCount} {totalCount > 1 ? "Results" : "Result"} </span>
                      </span>
                    </div>
                    <div 
                      className="col-sm d-flex justify-content-start justify-content-sm-end align-items-center"
                    >
                      <span className="font-size-4 mr-3">Sort by:</span>
                      <Select
                          defaultValue={sortByDefaultValue}
                          options={sortByDefaultOptions}
                          border={false}
                          name="sort-by"
                          id="sort-by"
                          placeholder=""
                          onChangeFn={(e) => handleSortFieldChange(e.value)}
                          openMenuOnFocus={true}
                          tabSelectsValue={false}
                          accentColor="primary"
                      />
                      {/* <Form.Select
                        className="p-3 border-0 arrow-3 arrow-3-black text-black-2"
                        border={true}
                        value={sortKey}
                        onChange={(e) => handleSortFieldChange(e.target.value)}
                      >
                        {defaultSortOptions.map((option)=>(
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </Form.Select> */}
                    </div>
                  </Row>
                  
                  {/* <!-- Vacancies list --> */}
                  <ul 
                    className="post-job-bx"
                    style={{listStyle:'none', margin:0, padding:0}}
                  >
                    {vacancies.map((item) => (<VacancyListItem itemData={item.data.fields} />))}
                  </ul>
                  
                  {/* <!-- Load More --> */}
                  <div className="text-center mt-3 mb-20">
                    {hasMore ? (
                      <Button 
                        variant="link"
                        className="font-weight-bold text-uppercase font-size-3"
                        onClick={handleLoadMore}>Load More <i className="fas fa-sort-down ml-3"></i></Button>
                    ) : (
                      <p>That's all</p>
                    )}
                  </div>
                  {/* <!-- form end --> */}
                </div>
              </Row>
            </Container>
            <Content />
            <Slider />
          </div>
        </PageWrapper>
      </AuthenticatedTemplate>
    </>
  );
};
export default SearchGrid;


// Display only published vacancies
// Filter Published = true
export const query = graphql`
{
  allSharePointVacanciesList (filter: {data: {fields: {Published: {eq: true}}}})
  {
    nodes {
      data {
        fields {
          id
          Position
          Company
        	Office
          Title
          Created
          Experience
          WorkingHours
          JobType
          Function
          Created
          ShortDescription
          JobDescription
          VacancyClosingDate
          slug
        }
      }
    }
  }
}
`
