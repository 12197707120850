import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Moment from 'moment';

const Item = styled.li`
    margin-bottom: 24px;
    
    & ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    & .post-bx {
        padding: 24px;
        background-color: #fff;
        border: thin solid ${({ theme }) => 
            theme.colors.cofraBeige ? theme.colors.cofraBeige : "silver" };
        border-left-width: 5px;
        border-radius: 4px;
        // box-shadow: 0 0 10px 0 rgb(0 24 128 / 10%);
        display: block;
        position: relative;
    }
    & .post-bx:hover {
        border-left-color: ${({ theme }) => 
            theme.colors.primary ? theme.colors.primary : "black" };
    }
    & .param-dt {
        font-size: 12px;
        color: #797979;
    }
`;

const formatDate = (date) => {
    Moment.locale('en');
    return Moment(date).format('D MMM Y');
}

const VacancyListItem = ({ itemData }) => {
    const jobParams = [
        {
            label: "Location", 
            value: itemData.Office,
            icon: "fas fa-map-marker-alt",
        },
        {
            label: "Experience", 
            value: itemData.Experience,
            icon: "fas fa-star",
        },
        {
            label: "Working hours", 
            value: itemData.WorkingHours,
            icon: "fas fa-clock",
        },
        {
            label: "Job Type", 
            value: itemData.JobType,
            icon: "fas fa-file-contract",
        },
        {
            label: "Job Category",
            value: itemData.Function,
            icon: "fas fa-tag",
        },
        {
            label: "Posted", 
            value: formatDate(itemData.Created),
            icon: "far fa-calendar-check",
        }
    ]
    return (
        <Item 
            key={itemData.slug}
            data-aos="fade-in"
            data-aos-duration="800"
            data-aos-delay="200"
        >
            <div className="post-bx">
                <div className="d-flex m-b30">
                    <div className="job-post-info">
                        <h2 className="font-size-10 mb-8">
                            <Link to={`/vacancy/${itemData.slug}`} className="text-decoration-none text-black">{itemData.Position}</Link>
                        </h2>
                        <div className="my-7">
                            <span className="font-size-7">{ itemData.Company }</span>
                        </div>
                        <dl className="job-parameters container d-flex px-0 m-0 row justify-content-start">
                            {jobParams.map((p)=>(
                                <div className="col-6 col-sm-4 col-xl-3 mb-6 pl-0">
                                    <dt>
                                        <span className="param-dt">{p.label}</span>
                                    </dt>
                                    <dd>
                                        <i className={`${p.icon} mr-3 d-inline`}></i>
                                        <span className="param-dd  d-inline">{p.value}</span>
                                    </dd>
                                </div> 
                            ))}
                        </dl>
                        <div className="" dangerouslySetInnerHTML={{ __html: itemData.ShortDescription }} />
                    </div>
                </div>
            </div>
        </Item>
    )
};

export default VacancyListItem;