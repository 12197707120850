import React, {Component} from 'react'
import {Container, Form, Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import styled from 'styled-components'
import { device } from "../../utils"
import FieldLocation from './FieldLocation'
import queryString from "query-string"
import { navigate } from 'gatsby'

const SearchBarInner = styled.div`

		& .find-job-bx {
			text-align: left;
			position: relative;
			background-color: #fff;
			border-radius: 4px;
		}
		& .find-job-bx {
			& form {
				padding: 30px;
				background-color: hsla(0,0%,100%,.95);
				border-radius: 4px;
				box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);

				@media ${device.md} {
					padding: 30px 30px 5px;
				}
			}
			& .form-group {
				position: relative;
				& label {
					color: #000;
					font-size: 15px;
					position: absolute;
					top: 10px;
					left: 0;
					margin: 0;
					font-weight: 400;
					z-index: 0;
					transition: all .5s;
					-o-transition: all .5s;
					-ms-transition: all .5s;
					-webkit-transition: all .5s;
					-moz-transition: all .5s;
				}
				&.focused label, &.filled label {
						top: -12px;
						font-size: 12px;
						opacity: .4;
					}
				}
			}
			& .input-group {
				z-index: 100;
			}
			& .form-control {
				padding: 0;
				height: 50px;
				color: #000;
				font-size: 16px;
			}
			& .form-control {
				padding: 0;
				height: 50px !important;
				color: #000;
				font-size: 16px;
			}
			& .form-control, .input-group-text {
				border-radius: 0;
				background-color: transparent;
				border-width: 0 0 2px;
				border-color: ${({ theme }) => 
					theme.colors.primary ? theme.colors.primary : "#2e55fa;" };
			}
			& .input-group-text {
				padding: 0;
				color: ${({ theme }) => 
					theme.colors.cofraBlack ? theme.colors.cofraBlack : "#2e55fa;" };
				
				& .reset-icon {
					color: #c4c4c4;

					&:hover {
						color: #8e8e8e;
					}
				}
			}
		}
`;

class SearchBar extends Component{
	
	constructor (props) {
		super(props)

		this.state = {
			term: this.props.defaultTerm || "",
			location: this.props.defaultLocation || "",
			showTermReset: this.props.defaultTerm || false,
			showLocationReset: this.props.defaultLocation || false,
			termInputFocused: false,
			termInputFilled: this.props.defaultTerm || false,
			locationInputFocused: false,
			locationInputFilled: this.props.defaultLocation || false,
		}
		
		this.inputTerm = React.createRef();
		this.inputLocation = React.createRef();

		this.handleTermInputChange = this.handleTermInputChange.bind(this);
		this.handleTermInputBlur = this.handleTermInputBlur.bind(this);
		this.handleTermInputFocus = this.handleTermInputFocus.bind(this);

		this.handleLocationChange = this.handleLocationChange.bind(this);
		this.handleLocationInputBlur = this.handleLocationInputBlur.bind(this);
		this.handleLocationInputFocus = this.handleLocationInputFocus.bind(this);

		this.handleTermResetClicked = this.handleTermResetClicked.bind(this);
		this.handleLocationResetClicked = this.handleLocationResetClicked.bind(this);

	}

	handleTermInputChange(e) {
		const target = e.target;
    	const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({
			term: value,
			showTermReset: value ? true : false
		});
	}

	handleLocationChange(e) {
		const value = e ? e.value : ""
		this.setState({
			location: value,
			showLocationReset: value ? true : false,
			locationInputFilled: value ? true : false,
			locationInputFocused: false
		});
	}

	handleTermInputBlur(e) {
		this.setState({
			termInputFilled: e.target.value ? true : false, 
			termInputFocused: false
		})
	}

	handleLocationInputBlur(e) {
		this.setState({
			locationInputFocused: false,
			locationInputFilled: this.state.location ? true : false
		 })
	}

	handleTermInputFocus(e) {
		this.setState({termInputFocused: true })
	}

	handleLocationInputFocus(e) {
		this.setState({
			locationInputFocused: true
		 })
	}

	handleTermResetClicked(e) {
		this.setState({
			term: "",
			showTermReset: false,
			termInputFocused: false,
			termInputFilled: false
		})
	}

	handleLocationResetClicked(e) {
		this.inputLocation.current.clearValue()
		this.setState({
			location: "",
			showLocationReset: false,
			locationInputFocused: false,
			locationInputFilled: false
		})
	}

	handleFormSubmit(e) {
		e.preventDefault()
		
		// if not on search page, redirect. Pass filter values
		if (this.props.redirect) {
			const query = {
				q: this.state.term,
				location: this.state.location
			}
			const qs = queryString.stringify(query)
			navigate( `/search/?${qs}` )
			return
		}
		
		this.props.setFilters((filters) => { 
			return { ...filters, term: this.state.term, location: this.state.location }
		})
	}

	componentDidMount() {

	}
	render(){
		return(
			<SearchBarInner className="browse-job-find">
				<Container>
					<div className="find-job-bx">
						<Form 
							className="dezPlaceAni" 
							onSubmit={(e) => this.handleFormSubmit(e)} >
							<div className="row">
								<div className="col-md-4">
									<div className={`form-group form-group-term ${this.state.termInputFocused ? 'focused' : ''} ${this.state.termInputFilled ? 'filled' : ''}`}>
										<Form.Label htmlFor="inputTerm">Title or keywords</Form.Label>
										<div className="input-group term">
											<Form.Control 
												type="text" 
												id="term"
												name="term"
												value={this.state.term}
												onChange={this.handleTermInputChange}
												onBlur={this.handleTermInputBlur}
												onFocus={this.handleTermInputFocus}
												className="form-control animate pr-3"
												placeholder=""
												autoComplete="off"
												autoCorrect="off"
												ref={this.inputTerm}
											/>
											{this.state.showTermReset && 
												<div 
												onClick={this.handleTermResetClicked}
												className="input-group-append"
												style={{cursor: 'pointer'}}
												>
													<span className="input-group-text">
														<OverlayTrigger
															placement="top"
															overlay={<Tooltip 
																id="reset-term-tooltip"
																style={{zIndex: 9999}}>Clear</Tooltip>
															}
														>
													
															<i className="fa fa-times mr-6 reset-icon"></i>
														</OverlayTrigger>
													</span>
												</div>
											}
											<div className="input-group-append">
											  	<span className="input-group-text">
													<i className="fa fa-search"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-5">
									<div className={`form-group form-group-location ${this.state.locationInputFocused ? 'focused' : ''} ${this.state.locationInputFilled ? 'filled' : ''}`}>
										<Form.Label 
											htmlFor="inputLocation"
										>Location</Form.Label>
										<div className="input-group location">
											<FieldLocation
												className="h-100 arrow-3 font-size-4 d-flex align-items-center form-control animate"
												defaultValue={
													{
														value: this.state.location, 
														label: this.state.location
													}
												}
												getRef={this.inputLocation}
												onChangeFn={this.handleLocationChange}
												onFocusFn={this.handleLocationInputFocus}
												onBlurFn={this.handleLocationInputBlur}
												indicator={false}
												openMenuOnFocus={true}
												tabSelectsValue={false}
												accentColor="primary"
											/>
											{this.state.showLocationReset && <div 
												onClick={this.handleLocationResetClicked}
												className="input-group-append"
												style={{cursor: 'pointer'}}
											>
											  	<span className="input-group-text">
													<OverlayTrigger
														placement="top"
														overlay={<Tooltip 
															id="reset-term-tooltip"
															style={{zIndex: 9999}}>Clear</Tooltip>
														}
													>
												
														<i className="fa fa-times mr-6 reset-icon"></i>
													</OverlayTrigger>
												</span>
											</div>}
											<div className="input-group-append">
											  <span className="input-group-text"><i className="fa fa-map-marker-alt"></i></span>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<Button variant="primary" type="submit" className="text-uppercase btn-block">Search</Button>
								</div>
							</div>
						</Form>
					</div>
				</Container>
			</SearchBarInner>
		)
	}
}
export default SearchBar;