import React from 'react'
import { useLocations } from '../../hooks/useLocations'
import { Select } from '../Core'

const FieldLocation = (props) => {
    let locations = useLocations()

    // move Remote to the end
    if ( locations.includes("Remote") ) {
        locations = locations.filter((l) => l !== 'Remote')
        locations.push("Remote")
    }

    // prepare dropdown options
    locations = locations.map((l) => ({ "value": l, "label": l }))

    // add first empty option
    locations.unshift({ value: "", label: ""})

    return (
        <>
            <Select
                options={locations}
                border={false}
                name="location"
                id="location"
                placeholder=""
                {...props}
            />
        </>
        // <FormControl
        //     {...props}
        //     type="text" 
        //     id="location"
        //     name="location"
        //     className="form-control animate"
        //     placeholder=""
        //     autoComplete="off"
        // />
    )
}

export default FieldLocation