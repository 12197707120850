import queryString from 'query-string'
import { getAllFilterQueryLabels, getAllQueryLabels } from "../utils/filters"

export function getSearchResultsClientSide(query, initialData) {
  const {filters, count, term, sortKey} = query
  let results = []
  // create term search query
  
  // tokenize the search term
  const terms = term == '' ? [] : term
    .toLowerCase()
    .replace(/\,|\.|\:|\;/ig, '')
    .split(' ')
    .filter(function(t) {
        t = t.trim()
        return t !== '' && t.length > 1
    })

  const containsTerm = (vacancy) => {
    let hay = JSON.stringify(vacancy).toLowerCase()
    let match = 0
    terms.forEach(t => {
      if (hay.includes(t)) match++
    })
    return match
  }
  
  const containsFilters = (vacancy) => {
    let hay = vacancy.data.fields
    let matches = []
    filters.forEach(filter => {
       matches.push(filter.options.includes(hay[filter.field]))
    })
    //match = (vacancy.data.fields['Company'] == 'Sunrock' || vacancy.data.fields['Company'] == 'Anthos Family Office') 
    //&& 
    //(vacancy.data.fields['Function'] == 'Strategy' || vacancy.data.fields['Function'] == 'HR')
    return !matches.includes(false)
  }

  // filter terms
  results = initialData.filter((i) => { 
    // check for terms
    return (!term || containsTerm(i))
      && 
      (!filters.length || containsFilters(i))
  })
  
  // sort
  switch (sortKey) {
    case "VacancyClosingDate":
      results.sort((a, b) => {
        a = new Date(a.data.fields[sortKey])
        b = new Date(b.data.fields[sortKey])
        return a-b
      })
      break;
    
    case "Office" :
      results.sort((a, b) => {
        a = a.data.fields[sortKey].toString()
        b = b.data.fields[sortKey].toString()
        return a.localeCompare(b, 'en', { sensitivity: 'base' });
      })
      break;
    
    case "Company" :
      results.sort((a, b) => {
        a = a.data.fields[sortKey].toString()
        b = b.data.fields[sortKey].toString()
        return a.localeCompare(b, 'en', { sensitivity: 'base' });
      })
      break;

    default:
      results.sort((a, b) => {
        a = new Date(a.data.fields['Created'])
        b = new Date(b.data.fields['Created'])
        return b-a
      })
      break;
  }
  
  return results
}

export async function getSearchResults(query) {
  
  // pass @query to API (Sharepoint) > retrieve array of ids > filter static data with the ids
  const response = await fetch("/api/vacancies/search", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(query)
  })

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  
  return await response.json();
}

/**
 * Extracts default search values from the query string or object
 * @param {string|object} query
 */
export function getValuesFromQuery(query) {
  const isClient = typeof query === 'string'
  
  let filters = getAllFilterQueryLabels();

  const {
    q: term,
    s: sortKey,
    location: location
  } = isClient ? queryString.parse(query) : query

  Object.assign( filters, queryString.parse(query) )

  //arrayify filter options
  for (const i in filters) {
    filters[i] = arrayify(filters[i])
  }

  return Object.assign( filters, { term, sortKey, location } )
}

function arrayify(value) {
  if (!value) {
    return []
  }
  if (!Array.isArray(value)) {
    return [value]
  }
  return value
}

// Prepares query for the API call to Sharepoint
export function createQuery(query) {
  const fields = getAllQueryLabels()
  let _filters = []

  for (const param in query) {
    if ( fields.includes(param) && arrayify(query[param]).length ) {
      _filters.push({
        field: param,
        options: arrayify(query[param])
      })
    }
  }

  // add location as filter
  if ( query.location ) {
    _filters.push({
      field: "Office",
      options: [query.location]
    })
  }

  const searchQuery = {
    count: query.count || 20,
    term: query.term || "",
    sortKey: query.sortKey || "",
    filters: _filters
  }

  return searchQuery
}

// Helper fn for createQuery()
function makeFilter(field, selectedItems) {
    if (!selectedItems?.length) return
    if (selectedItems && !Array.isArray(selectedItems)) {
      selectedItems = [selectedItems]
    }
    return `(${selectedItems
      .map((item) => `${field}:${JSON.stringify(item)}`)
      .join(" OR ")})`
  }