import React from "react";

const Sidebar = ({children}) => {
  
  return (
      <aside className="sidebar-filter bg-white">
          {children}
      </aside>
  );
};

export default Sidebar;