import { useStaticQuery, graphql } from "gatsby"

export const useLocations = () => { 

  // const { allGreenhouseOffice: {locations} } = useStaticQuery(
  //   graphql`
  //     query locations {
  //       allGreenhouseOffice {
  //         locations: edges {
  //           node {
  //             location
  //           }
  //         }
  //       }
  //     }
  //   `
  // )
  //return locations.map((l) => l.node.location)
  
  // Hard code the locations instead of pulling from Greenhouse
  const locations = [
    "Amsterdam, Netherlands",
    "Vilvoorde, Belgium",
    "Oakville, Canada",
    "Düsseldorf, Germany",
    "St. Helier, Jersey",
    "London, United Kingdom",
    "Strassen, Luxembourg",
    "New York City, United States",
    "Zug, Switzerland",
    "Remote"
  ]
  
  return locations
}
