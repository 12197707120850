import React from "react";
import { withTheme } from "styled-components";
import Select from "react-select";

const defaultOptions = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const getCustomStyles = (theme, accentColor, bg, border, indicator) => {
  return {
    dropdownIndicator: () => {
      return {
        display: !indicator && "none",
      };
    },
    indicatorSeparator: () => {},
    option: (provided, state) => {
      return {
        ...provided,
        color: state.isSelected ? theme.colors[accentColor] : theme.colors.dark,
        textAlign: "left",
        backgroundColor: state.isDisabled
          ? undefined
          : state.isSelected
          ? bg
          : state.isFocused
          ? '#f6f6f6f2'
          : undefined,
      };
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        padding: "2px 0"
      }
    },
    control: (provided, state) => {
      return {
        ...provided,
        border: !border
          ? "none"
          : state.menuIsOpen || state.isFocused
          ? `1px solid ${theme.colors[accentColor]} !important`
          : `1px solid ${theme.colors.border} !important`,
        borderRadius: 10,
        //padding: "0.25rem 2rem",
        paddingLeft: 0,
        paddingRight: 0,
        width: "100%",
        minWidth: 200,
        height: "45px",
        outline: "none",
        boxShadow: "none",
        textAlign: "left",
        backgroundColor: "transparent",
      };
    },
  };
};

const SelectStyled = ({
  theme,
  bg = "#fff",
  border = true,
  accentColor = "primary",
  name = "item",
  indicator = true,
  options = defaultOptions,
  onChangeFn = () => {},
  onBlurFn = () => {},
  onFocusFn = () => {},
  getRef = () => {},
  ...rest
}) => {
  
  return (
    <Select
      aria-label="Select location"
      styles={getCustomStyles(theme, accentColor, bg, border, indicator)}
      name={name}
      options={options}
      onChange={onChangeFn}
      onFocus={onFocusFn}
      onBlur={onBlurFn}
      ref={getRef}
      {...rest}
    />
  );
};

export default withTheme(SelectStyled);
