import { useStaticQuery, graphql } from "gatsby"
import { getDefaultFilters } from "../utils/filters"

export const useFilters = () => { 

  const { allSharePointFiltersList: { filters }, allGreenhouseDepartment: { departments } } = useStaticQuery(
    graphql`
      query filters {
        allSharePointFiltersList(sort: {fields: data___fields___Order0}) {
          filters: group(field: data___fields___Category) {
            queryLabel: fieldValue
            count: totalCount
            options: edges {
              node {
                data {
                  fields {
                    Title
                  }
                }
              }
            }
          }
        }
        allGreenhouseDepartment {
          departments: edges {
            node {
              name
            }
          }
        }
      }
    `
  )

  /*
  * Exclude "Templates" from departments
  * COFRA Greenhouse has as last department "Templates" which we don't want on the portal.
  */
  const exclude = ['Templates']
  const filteredDepartments = departments.filter((d) => !exclude.includes(d.node.name))
  
  // fill default filters list with distinct values
  const defFilters = getDefaultFilters()

  defFilters.forEach((f) => {
    
    if (f.queryLabel === "Company") {
      f['options'] = filteredDepartments.map((d) => d.node.name )
      return
    }

    const cat = filters.filter((c) => c.queryLabel === f.queryLabel).shift()
    if ( !cat ) {
      return
    }

    f['options'] = cat.options.map((o) => o?.node?.data?.fields?.Title)
  })
  
  return defFilters
}
