import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

function AccordionToggle({ children, eventKey }) {
    const toggleChevron = useAccordionButton(eventKey, () => {
            //console.log(eventKey)
        }
    );
  
    return (
      <button
        type="button"
        className="bg-transparent border-0 float-right"
        onClick={toggleChevron}
      >
        {children}
      </button>
    );
}

const CheckFilter = ({
    items, 
    queryLabel,
    name,
    selectedItems = [],
    setSelectedItems
    }) => {

    const toggleItem = ({ currentTarget: input }) => {
        if (input.checked) {
            setSelectedItems([...selectedItems, input.value])
        } else {
            const idx = selectedItems.indexOf(input.value)
            if (idx === -1) {
                return
            }
            const newItems = [
                ...selectedItems.slice(0, idx),
                ...selectedItems.slice(idx + 1),
            ]
            setSelectedItems(newItems)
        }
    }

  return (
    <>
        <Accordion.Item>
            <Card className="acod-head rounded-4">
                <Card.Header as="h3" className="acod-title font-weight-bold font-family-primary border-bottom-0 font-size-4 bg-transparent font-family-primarymy-6">
                    {name}
                    <AccordionToggle  
                        eventKey={queryLabel}>
                            <i className="fas fa-chevron-down font-size-2"></i>
                    </AccordionToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={queryLabel} className="acod-body" id={`filter-${queryLabel}`}>
                    <Card.Body className="acod-content pt-0">
                        {items.map((item) => (
                            <label
                                className={`${selectedItems.includes(item) ? 'selectedLabel' : ''} d-flex`}
                                key={item}
                            >
                            <input
                                type="checkbox"
                                className="checkbox mr-2 align-self-center"
                                onChange={toggleItem}
                                value={item}
                                checked={selectedItems.includes(item)}
                            />{" "}
                            {item || "None"}
                            </label>
                        ))}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion.Item>
    </>
  )
};

export default CheckFilter;