const defaultFilters = [
    {
        label: "Company",
        handle: "company",
        queryLabel: "Company",
        options: []
    },
    {
        label: "Job Category",
        handle: "function",
        queryLabel: "Function",
        options: []
    },
    {
        label: "Experience",
        handle: "experience",
        queryLabel: "Experience",
        options: []
    },
    {
        label: "Job Type",
        handle: "job type",
        queryLabel: "JobType",
        options: []
    },
    {
        label: "Working hours",
        handle: "working hours",
        queryLabel: "WorkingHours",
        options: []
    }
]

export function getDefaultFilters() {
    return defaultFilters
}

/**
 * Returns object with filters where property is filter's query label
 * {queryLabel: [options], queryLabel: [options]}
 */
export function makeAllFiltersByQueryLabel(defaultFilters) {
    let result = {}
    defaultFilters.map( (f) => result[f.queryLabel] = f.options )
    return result
}

/**
 * Get object with filters' query labels and empty values
 * returns {queryLabel: ''}
 */
export function getAllFilterQueryLabels() {
    const result = {}
    defaultFilters.forEach( (f) => (result[f.queryLabel]='') )
    return result
}

/**
 * Get array of all queryLabels
 */
export function getAllQueryLabels() {
    return defaultFilters.map( filter => filter.queryLabel )
}

/** 
 * Sort array that has string values with numerics at the beginning
 * Handy for sorting Experience filter
 * Example: [10+ years, 0-3 years, 3-6 years] will be returned as [0-3 years, 3-6 years, 10+ years]
*/
export function sortArrayWithNumerics(arr) {
    return arr.sort((a, b) => ( parseInt(a)-parseInt(b) ))
}