import { useEffect, useState, useMemo } from "react"
import queryString from "query-string"
import { getSearchResults, getSearchResultsClientSide, createQuery } from "../utils/search"

function makeQueryStringValue(allItems, selectedItems) {
  if (allItems.length === selectedItems.length) {
    return []
  }
  return selectedItems
}

export function useVacancySearch(
    filters,
    allFilters,
    sortKey,
    pause = false,
    count = 20,
    initialData = [],
    initialFilters,
  ) {

    const [query, setQuery] = useState(filters)
    
    const { term, location } = filters
  
    const [initialRender, setInitialRender] = useState(true)

    const [totalCount, setTotalCount] = useState(0)

    // only fetch after the filters have changed
    const shouldPause = useMemo(() => (query === initialFilters) || pause, [query, pause, initialFilters])
    
    const [result, setResult] = useState( {data: initialData} )
    
    // get filtered data from the API
    // and filter static content
    useEffect( () => {
      const searchQuery = createQuery(query)
      if (searchQuery.term === '' && searchQuery.location === '' && !searchQuery.filters.length && searchQuery.sortKey === '') {
        setTotalCount(initialData.length)
        setResult({
          data: initialData
        })
      } else {
        const results = getSearchResultsClientSide(searchQuery, initialData)
        setTotalCount(results.length)
        setResult({ data: results })
        // getSearchResults(searchQuery)
        //   .then(data => {
        //     const results = data.map((i) => initialData.find((j) => j.data.fields.id === i));
        //     setTotalCount(results.length)
        //     setResult({
        //       data: results
        //     })
        //   })
        //   .catch(error => {
        //     console.log(error.message)
        //   });
      }
    }, [query])

    // handle the url query params
    useEffect(() => {
      const _query = {}

      for (const param in allFilters) {
        _query[param] = makeQueryStringValue(allFilters[param], filters[param] || [])
      } 
      _query['q'] = term || undefined
      _query['s'] = sortKey || undefined
      _query['location'] = location || undefined

      const qs = queryString.stringify(_query)
  
      const url = new URL(window.location.href)
      url.search = qs
      url.hash = ""
      window.history.replaceState({}, null, url.toString())
      
      _query['term'] = term || ''
      _query['location'] = location || ''
      _query['sortKey'] = sortKey || ''
      _query['count'] = count

      setQuery(_query)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, sortKey])
  
    // TODO: count filters
    // result may be used as css class descriptor in filters panel
    const filterCount = 0
      //(filters.tags.length === allTags.length ? 0 : filters.tags.length)
    
    const [vacancies, setVacancies] = useState([...result.data.slice(0, count)])
    const [loadMore, setLoadMore] = useState(false)
    const [hasMore, setHasMore] = useState(result.data.length > count)
    
    const allVacancies = useMemo(() => {
      if (query === initialFilters) {
        return initialData
      }
      
      if (result.data && initialRender) setInitialRender(false)
      
      setVacancies([...result.data.slice(0, count)])
      setHasMore(result.data.length > count)
      
      return result.data || []
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, result.data, initialData, initialFilters])
  
    const isFetching = !initialRender && result.fetching

    /* 
    * Load more logic
    * For now we grab all search results coming from the API and paginate them in 
    * TODO: fetch data from API as pages
    */
    // Handle loading more articles    
    useEffect(() => {
      if (loadMore && hasMore) {
        const currentLength = vacancies.length
        const isMore = currentLength < allVacancies.length
        const nextResults = isMore
          ? allVacancies.slice(currentLength, currentLength + count)
          : []
        setVacancies([...vacancies, ...nextResults])
        setLoadMore(false)
      }
    }, [loadMore, hasMore]) //eslint-disable-line
  
    //Check if there is more
    useEffect(() => {
      const isMore = vacancies.length < allVacancies.length
      setHasMore(isMore)
    }, [vacancies]) //eslint-disable-line  

    return {
      data: result.data,
      isFetching,
      vacancies,
      filterCount,
      hasMore,
      setLoadMore,
      totalCount
    }
  }