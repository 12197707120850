import React from "react";
import { Container, Row, Accordion, Card } from "react-bootstrap";
import CheckFilter from "./CheckFilter";
import { useFilters } from "../../hooks/useFilters";

const Filters = ({
    filters,
    setFilters
    }) => {
    
    const updateFilter = (key, value) => {
        setFilters((filters) => ({ ...filters, [key]: value }))
    }
    
    const allFilters = useFilters()

    return (
        <div>
            <Container className="filters-head">
                <Row className="pl-3">
                    <div>
                        <i className="fas fa-filter font-size-4 mr-3"></i>
                        <span className="title font-family-primary font-size-4">Refine by</span>
                        <button 
                            style={{display:'none'}}
                            className="font-size-2 ml-auto pr-3 align-self-center"
                        >
                            Reset All
                        </button>
                    </div>
                </Row>
            </Container>
            <Accordion defaultActiveKey={allFilters[0].queryLabel}>
                { allFilters.map((filter)=>
                    (
                        <CheckFilter
                            items={filter.options}
                            queryLabel={filter.queryLabel}
                            name={filter.label}
                            selectedItems={filters[filter.queryLabel]}
                            setSelectedItems={(value) => updateFilter(filter.queryLabel, value)}
                        />
                    )
                ) }
            </Accordion>
        </div>
    )
}
export default Filters;
